<template>
  <div class="wrapper" v-click-outside="focusOut">
    <FloatLabel 
      :label="label"
      :optional="optional"
      :ask="true"
      :tooltip="locationTooltipContent"
    >
      <div :class="[`${error ? 'invalid' : ''}`]" class="input-wrapper d-flex justify-content-between align-items-center">
        <input
          class="shadow-none form-control autocomplete-custom"
          v-model.trim="inputValue"
          :name="name"
          :placeholder="placeholder"
          autocomplete="off"
          ref="autocomplete"
          :class="[`${error ? 'invalid' : ''}`]"
          @focus="initAutocomplete"
          required
          @input="handleOnInput"
        />
      </div>
    </FloatLabel>
    <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">{{ error ? helperText : '' }}</div>
  </div>
</template>

<script>
import { logError } from "@/helper/utils";
export default {
  name: 'GooglePlaceAutocomplete',
  components: { 
   FloatLabel: () => import('./FloatLabel.vue')
  },
  model: {
    prop: 'selectValue',
    event: 'change'
  },
  data() {
    return {
      autocomplete: '',
      inputValue: this.selectValue,
      locationTooltipContent: "Full address is required to determine the correct suburb and region. AutoFlip only stores your suburb and region.",
    }
  },
  props: {
    selectValue: {
      type: [Number, String],
      default: () => ""
    },
    label: String,
    name: {
      type: String,
      default: () => ""
    },
    error: Boolean,
    isFocus: Boolean,
    optional: Boolean,
    placeholder: String,
    helperText: String,
    isMobile: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    initAutocomplete() {
      this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs['autocomplete'], {
        componentRestrictions: {
          country: ['nz'],
        },
        // fields: ['address_components'],
        // types: ['locality', 'street_address', 'postal_code', 'postal_code_prefix'],
        types: ['geocode', 'establishment'],
      });
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
    fillInAddress() {
      try {
        const place = this.autocomplete.getPlace();
        if (place?.address_components) {
          const region = place.address_components.find(
            (component) => ['administrative_area_level_1'].some((type) => component.types.includes(type))
          );
          const suburb = place.address_components.find(
            (component) => ['locality', 'sublocality_level_1', 'sublocality'].some((type) => component.types.includes(type))
          );
          let address = Array.from(
            new Set([
              suburb?.long_name,
              region?.short_name,
            ])
          )
            .filter(Boolean)
            .join(" ");
          this.inputValue = address;
          this.focusOut();
        } else {
          this.inputValue = this.$refs['autocomplete'].value.replace(', New Zealand', '');
          this.focusOut();
        }
      } catch (error) {
        logError(`Google place API error: ${error}`);
      }
    },
    focusOut() {
      this.$emit('change', this.inputValue);
    },
    handleOnInput() {
      this.$emit('input', this.inputValue);
    },
    hoverTooltip: function() {
      if (!this.isMobile) {
        this.$refs.locationTooltip.style.visibility = "visible";
      }
    },
    blurTooltip: function() {
      this.$refs.locationTooltip.style.visibility = "hidden";
    },
    handleMobileTap: function(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.isMobile) {
        this.$refs.locationTooltip.style.visibility = "visible";
      }
    },
  },
  mounted() {
    this.initAutocomplete();
  },
}
</script>

<style lang="scss">
.pac-container {
  z-index: 100002;
  box-shadow: 0px 0px 1px 0px #bebebe !important;
}
.pac-item {
  padding: 5px;
  cursor: pointer;
}
</style>
